$( document ).ready(function() {
    // console.log( "ready!" );
	AOS.init();
	
	var _grid;
	var _hasTag;
	startMasonry();

	var elems = $('.grid').children();
	setTimeout(function(){
		$(window).resize();
	},0);

	$(window).on("hashchange", function(e){
		_hasTag = location.hash.replace("#", "");
		filterGirdItem(elems, _hasTag);
		updateClientMenuItem(_hasTag);
	});

	if(window.location.hash){
		_hasTag = location.hash.replace("#", "");
		filterGirdItem(elems, _hasTag);
		updateClientMenuItem(_hasTag);
		updateFilterName(_hasTag);

		// window.lastFilter = _hasTag;
		$('#back').attr("href", $('#back').attr("href")+"#"+_hasTag );
	}
	
	$(window).on("resize",debounceResize(function(e){
		//console.log("resize!");
		fixGridItemHeight(elems);
		_grid.masonry('layout');

		$('.projects .grid').css("min-height", "85vh");
	}));

	_grid.on('layoutComplete', function( event, laidOutItems ) {
	  	//console.log( 'Masonry layout completed on');
	});
	

	$('#category-list a').on('click',function(e){	
		$('#category_select').html($(e.target).html());
		$('#client_select').html($('#client_select').data('default'));
		if($(e.target).data("cate") == 'all'){
			$('#client_select').html($('#client_select').data('default'));
			$('#category_select').html($('#category_select').data('default'));
		}
	});

	$('#client-list a').on('click',function(e){
		$('#client_select').html($(e.target).html());
	});

	$('#showAll').on('click', function(){
		$('#client_select').html($('#client_select').data('default'));
		$('#category_select').html($('#category_select').data('default'));
	});

	function updateFilterName(_filter){
		var ids = _filter.split(",");
		var clientId;
		var cateId = ids;

		if(ids.length >= 2){
			cateId = ids[0];
			clientId = ids[1];
		}

		// $('#category_select').html($(e.target).html());
		var cateList = $("#category-list").children();
		Object.keys(cateList).forEach(function(key){
			var item = $(cateList[key]);
			if(item.data("cate") == cateId){
				$('#category_select').html(item.html());
			}
		});

		if(clientId){
			var clientList = $("#client-list").children();
			Object.keys(clientList).forEach(function(key){
				var item = $(clientList[key]);
				if(item.data("client") == clientId){
					$('#client_select').html(item.html());
				}
			});
		}
	}

	function filterGirdItem(_items, _filter){
		for(var i=0; i<_items.length; i++){
			var _item = $(_items[i]);
			var _itemCateId = _item.data("cate");
			var _itemClientId = _item.data("client");

			_item.removeClass("disable");
			var linker = _item.find('a');
			var href = linker.attr("href");
			if(href){
				href = href.split("#");
				linker.attr('href',href[0]+"#"+_filter);
			}

			var ids = _filter.split(",");
			if(_item.hasClass('grid-sizer') || _item.hasClass('grid-sizer2') ){
				continue;
			}
			if(ids.length >= 2){
				if(ids[1] == _itemClientId){
					_item.removeClass('disable');
					$(".grid").prepend($(_item));
				}else{
					_item.addClass("disable");	
				}

				if(_itemCateId == ids[0] || ids[0] == "all"){
					_item.css("display", "block");
				}else{
					_item.css("display", "none");
				}

			}else if(_itemCateId == _filter || _filter == "all"){
				_item.css("display", "block");
			}else{
				_item.css("display", "none");
			}
		}

		$(window).resize();
		_grid.masonry('destroy');
		startMasonry();
	}

	function updateClientMenuItem(filter){
		var list = $("#client-list").children();
		for(var i=0; i<list.length; i++){
			var item = $(list[i]);
			var ids = filter.split(",");
			var cateId = (ids.length>=2)?ids[0]:ids;
			if(item.data("client") == "all"){
				// console.log('---');
				item.attr("href", "#"+cateId);
				continue;
			}
			if(item.data("cate") == cateId || cateId == 'all'){
				item.css("display", "block");
			}else{
				item.css("display", "none");
			}
		}
	}

	function fixGridItemHeight(_items){
		// $('.grid-sizer').attr("style", "");
		if(!$('.grid-sizer')[0])return;

		var gridWidth = Math.floor($('.grid-sizer')[0].getBoundingClientRect().width);
		var gridWidth2 = Math.floor($('.grid-sizer2')[0].getBoundingClientRect().width);
		
		var windowWidth = $(window).width();
		// console.log('windowWidth',windowWidth, $(window).outerWidth());
		if(windowWidth >= 1200 - 15 ){
			gridWidth2 = gridWidth *2;
		}
		// console.log( $('.grid-sizer2').width(), gridWidth2, $('.grid-sizer2')[0].getBoundingClientRect().width);
		for(var i=0; i<_items.length; i++){
			var _item = $(_items[i]);
			var hasClass = _item.hasClass("grid-item--width2");
			if(_item.hasClass("grid-sizer")){
				// _item.css("width", gridWidth);
				continue;
			};
			if(_item.hasClass("grid-sizer2")){
				// _item.css("width", gridWidth2);
				continue;
			};
			if(hasClass){
				// console.log('gridWidth2', gridWidth2);
				_item.css("height", gridWidth2);
				_item.css("width", gridWidth2);
			}else{
				_item.css("height", gridWidth);
				_item.css("width", gridWidth);
			}
		}
		$('.grid-sizer').attr("style", "");
		// $('.grid').attr("style", 'width:'+(gridWidth * gridColum)+'px !important');
	}

	function startMasonry(){
		_grid = $('.grid').masonry({
			// set itemSelector so .grid-sizer is not used in layout
			itemSelector: '.grid-item',
			// use element for option
			columnWidth: '.grid-sizer',
			percentPosition: true
		});
	};


	function debounceResize(func){
		var timer;
		return function(event){
		  if(timer) clearTimeout(timer);
		  timer = setTimeout(func,300,event);
		};
	}

	$(".carousel").swipe({
	  swipe: function(event, direction, distance, duration, fingerCount, fingerData) {
	
	    if (direction == 'left') $(this).carousel('next');
	    if (direction == 'right') $(this).carousel('prev');
	
	  },
	  allowPageScroll:"vertical"
	});

	$('.navbar-onepage a[href*="#"]').on('click', function (e) {
		if($('.navbar-onepage').data("page") == "home"){
			e.preventDefault();
			$('html, body').animate({
				scrollTop: $($(this).attr('href')).offset().top - 70
			}, 500, 'linear');
			$('.navbar-collapse').collapse('hide');
		}
	});
/*
	$('.navbar-nav>li>a').on('click', function(){
	    $('.navbar-collapse').collapse('hide');
	});
*/
	$('[data-fancybox="gallery"]').fancybox({
		
	});
	$("[data-fancybox]").fancybox({
		afterShow: function(){
	      $('.carousel').carousel('pause');
	   },
	   afterClose: function(){
	      $('.carousel').carousel();
	   }
	});
	$('.scrollTo').on('click', function (e) {
		e.preventDefault();
	
		$('html, body').animate({
			scrollTop: $($(this).attr('href')).offset().top - 70
		}, 500, 'linear');
	});
	
});
